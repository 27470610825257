const titleList = document.querySelectorAll('.title-service-nav .title-service__item a');
const blocks = document.querySelectorAll('.services-wrapper .services .services__item');

let prevIndex = 0;
const observer = new IntersectionObserver(entries => {
	console.log(titleList, prevIndex)
  if(entries[0].target.getBoundingClientRect().top < window.innerHeight/4){
		if(entries[0].isIntersecting){
			prevIndex = parseInt(entries[0].target.id) -1
		}else if(!entries[0].isIntersecting){
			prevIndex = parseInt(entries[0].target.id) 
		}
		console.log(titleList, prevIndex)
		titleList.forEach((title, index) => {
			title.classList.remove('is-active')
			if(index === prevIndex){
				title.classList.add('is-active')
			}
		})
	}

}, { threshold: 1 });
window.onload = () => {
	titleList.forEach((title, index) => {
		title.classList.remove('is-active')
		if(index === prevIndex){
			title.classList.add('is-active')
		}
	})
}
blocks.forEach(block => {
  observer.observe(block);
});
