import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const swiperDoctor = new Swiper(".doctors-swiper", {
    slidesPerView: 1,
    spaceBetween: 16,
    breakpoints: {
        768: {
            spaceBetween: 20,
            slidesPerView: 2,
        },
        992: {
            spaceBetween: 40,
            slidesPerView: 3,
        },
    },
    navigation: {
        prevEl: ".doctors-swiper .prev",
        nextEl: ".doctors-swiper .next",
    },

    pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
        clickable: true,
    },
    grabCursor: true,
    autoHeight: true,

});

const swiperBlog = new Swiper(".blog-swiper", {
    slidesPerView: 1,
    spaceBetween: 16,
    breakpoints: {
        768: {
            spaceBetween: 20,
            slidesPerView: 2,
        },
        992: {
            spaceBetween: 40,
            slidesPerView: 3,
        },
    },
    navigation: {
        prevEl: ".blog-swiper .prev",
        nextEl: ".blog-swiper .next",
    },

    pagination: {
        el: "#blog .swiper-pagination",
        dynamicBullets: true,
        clickable: true,
    },
    grabCursor: true,
    autoHeight: true,
    // loop:true,
    containerModifierClass: '.blog-swiper',
});


$(".blog-filter__category").on("click", (e) => {
    $(".blog-filter__category").removeClass("is-active");

    // Додаємо клас "is-active" до клікнутого елемента
    $(e.currentTarget).addClass("is-active");

    const category = $(e.currentTarget).data("category");

    // Знайдемо всі елементи слайдера, які мають таке ж значення атрибута "data-category"
    const elements = $(".blog-list__item").filter(`[data-category="${category}"]`);

    // Знайдемо індекс першого елемента відфільтрованого масиву елементів
    const index = $(".blog-list__item").index(elements[0]);

    // Прокрутимо слайдер до елементу за вказаним індексом
    swiperBlog.slideTo(index);
});


if (window.innerWidth <= 991) {
    $(".blog-filter").on("click", "li", (e) => {
        e.currentTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
        });
    });
}

const swiperReviews = new Swiper(".reviews-swiper", {
    slidesPerView: 1,
    speed: 1000,

    spaceBetween: 16,
    breakpoints: {
        768: {
            spaceBetween: 30,
            slidesPerView: 2,
        },
        992: {
            spaceBetween: 40,
            slidesPerView: 2,
        },
    },
    navigation: {
        prevEl: "#reviews .prev",
        nextEl: "#reviews .next",
    },
    loop: true,

    grabCursor: true,

});


const swiperGallery = new Swiper(".gallery-swiper", {
    slidesPerView: 1,
    autoplay: true,
    speed: 1000,

    spaceBetween: 16,

    navigation: {
        prevEl: "#gallery .prev",
        nextEl: "#gallery .next",
    },
    loop: true,

    grabCursor: true,

});


