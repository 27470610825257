import throttle from "lodash.throttle";
import {WOW} from "wowjs/dist/wow.min";
import refs from "./refs";
const {bodyEl} = refs;
const throttledHandleResize = throttle(handleResize, 200);

let currentBackdrop = null;

export const showBackdrop = (backdrop, hideOnResize = false) => {
    if (!backdrop) {
        return;
    }
    disableBodyScroll();

    backdrop.removeClass("is-hidden");
    backdrop.on("click", handleBackdropClick);
    $(window).on("keydown", handleKeyDown);
    currentBackdrop = backdrop;

    if (hideOnResize) {
        $(window).on("resize", throttledHandleResize);
    }
};

export const hideBackdrop = (backdrop) => {
    if (!backdrop) {
        return;
    }

    enableBodyScroll();

    backdrop.addClass("is-hidden");
    backdrop.removeClass("click", handleBackdropClick);
    $(window).off("keydown", handleKeyDown);
    $(window).off("resize", throttledHandleResize);

    currentBackdrop = null;
};

function handleBackdropClick(e) {
    if (e.target === e.currentTarget) {
        hideBackdrop(currentBackdrop);
    }
}

function handleKeyDown(e) {
    if (e.key === "Escape") {
        hideBackdrop(currentBackdrop);
    }
}

function handleResize() {
    const {innerWidth} = window;

    if (innerWidth >= 768) {
        hideBackdrop(currentBackdrop);
    }
}

document.addEventListener('DOMContentLoaded', function() {
    const links = document.querySelectorAll('.title-service__link');

    // Додаємо клас is-active на перший елемент списку
    links[0].classList.add('is-active');

    links.forEach(link => {
        link.addEventListener('click', () => {
            // Знімаємо клас is-active з усіх елементів
            links.forEach(item => item.classList.remove('is-active'));
            // Додаємо клас is-active на клікнутий елемент
            link.classList.add('is-active');
        });
    });
});


export function enableBodyScroll() {
    bodyEl.css("overflow-y", "scroll");
}

export function disableBodyScroll() {
    bodyEl.css("overflow-y", "hidden");
}

const firstCategoryButton = document.querySelector('.blog-filter__category');

if (firstCategoryButton !== null) {
    firstCategoryButton.classList.add('is-active');
    // Виконуйте інші дії, які вам потрібно здійснити після додавання класу 'is-active'
} else {
    console.log('Елемент .blog-filter__category не знайдено на цій сторінці.');
}


$("document").ready(function () {
    bodyEl.css("visibility", "visible");
    new WOW().init();
});


document.addEventListener("wpcf7mailsent", () => {
    const thankYouMessages = document.querySelectorAll('.thank-you-message');
    thankYouMessages.forEach(message => {
        message.style.opacity = '1';
				message.style['z-index'] = '9999';
    });
});