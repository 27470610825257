import "./popupManu";
import "bootstrap/dist/css/bootstrap.css";
import "modern-normalize/modern-normalize.css";
import 'intl-tel-input/build/css/intlTelInput.min.css';
import "./readMore";
import "./utils";
import "./swiper";
import "./modal";
import "./synchronize";



import "../css/main.scss";
 