import { showBackdrop, hideBackdrop } from './utils.js';
document.querySelectorAll('.popup-button_open').forEach((button) => {
		button.addEventListener('click', () => {
				showBackdrop($(document.querySelector(`.backdrop`)), true);
				if(document.querySelector(`.backdrop`).querySelector('.closeButton')){
				document.querySelector(`.backdrop`).querySelector('.closeButton').addEventListener('click', ()=>{
				    hideBackdrop($(document.querySelector(`.backdrop`)))
				})
				}
		});
})