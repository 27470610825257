  $(document).ready(function () {
     $(".read-more").click(function () {
         $(this).next().slideToggle(400);

         if ($(this).text() === 'читати більше') {
              $(this).text('приховати');
         } else {
             $(this).text('читати більше');
         }

         $(".read-more-wrapper").not($(this).next('.read-more-wrapper')).slideUp(400);
         $(".read-more").not($(this)).text('читати більше');
     });
 });